import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Divider } from '@material-ui/core';
import ResponsiveHeading from '../../../components/utils/responsive-heading';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <ResponsiveHeading primary="h4" mobile="h5" style={{
      paddingTop: '1rem'
    }} mdxType="ResponsiveHeading">
  Computer Visions feels different because it is different
    </ResponsiveHeading>
    <Divider mdxType="Divider" />
    <p>{`Here are just a few of the things that set us apart from the rest:`}</p>
    <ul>
      <li parentName="ul">{`Job placement assistance`}</li>
      <li parentName="ul">{`State of the art equipment`}</li>
      <li parentName="ul">{`Free class repeat privileges - No Limit`}</li>
      <li parentName="ul">{`Free Help Desk - No Limit`}</li>
      <li parentName="ul">{`Small class sizes guaranteed`}</li>
      <li parentName="ul">{`Comfortable Learning Environment`}</li>
      <li parentName="ul">{`Vendor approved Quality Training Manuals`}</li>
      <li parentName="ul">{`Computer Visions is an Adult Learning Center`}</li>
      <li parentName="ul">{`Microsoft Certified Partner for Learning Solutions`}</li>
      <li parentName="ul">{`Microsoft Solutions Provider`}</li>
      <li parentName="ul">{`Member of New York's Tech Valley`}</li>
      <li parentName="ul">{`VUE Testing Center`}</li>
      <li parentName="ul">{`MOS Testing Center`}</li>
      <li parentName="ul">{`NYS Certified Minority Woman-Owned Business`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      