import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Divider } from '@material-ui/core';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`Custom Solutions`}</h2>
    <p>{`Whether you require a single course for a small group or extensive training for your entire IT work force, Computer Visions offers convenience to fulfill your training needs.`}</p>
    <Divider mdxType="Divider" />
    <h2>{`Get What You Want`}</h2>
    <p>{`We offer highly customizable group training courses: desktop applications, web development, networking & operating systems, technical certification, professional development & skills, help desk.`}</p>
    <Divider mdxType="Divider" />
    <h2>{`Get It When & Where You Want It`}</h2>
    <p>{`All Computer Visions courses are available for presentation on-site or off-site for your convenience. We can roll out any combination of courses at one or more locations anywhere in the United States, according to your requirements and time table. Just let us know and we'll work with you!`}</p>
    <Divider mdxType="Divider" />
    <h2>{`Corporate Training Solutions`}</h2>
    <p>{`Corporations look to us to train their employees. Why? Because our proven training methods have increased employee proficiency and productivity. We assign one person to each of our corporate clients to ensure quality service, and we also offer extranet Web registration, management, reporting and billing for all clients. Simplicity, quality and outstanding service are our hallmarks.`}</p>
    <Divider mdxType="Divider" />
    <h2>{`E-Business Solutions`}</h2>
    <p>{`Many Internet consulting firms try to impress with big words and confusing jargon when it comes e-business. We don't. We offer simplicity and execution. We believe that the Internet does not need to be confusing. We believe that existing business systems can be easily and expertly integrated into powerful e-business systems.`}</p>
    <Divider mdxType="Divider" />
    <h2>{`Consulting Solutions`}</h2>
    <p>{`Consulting is about trust, service and accountability. Our consulting division is one of the fastest-growing around. Why? Clients trust us to provide excellent service and expertise. Visit our consulting section for more information.`}</p>
    <Divider mdxType="Divider" />
    <h2>{`Classroom and Computer Rentals`}</h2>
    <p>{`Need space and equipment to conduct specialized presentations or classes? We can help. With our world class classrooms and state of the art projection and presentation equipment, we take care of the details so you can have a successful presentation.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      